/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/
.tools_map{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 30px;
}
.tools_map .map_wrapper {
    width: 80%;
}
#sidebar-wrapper{
    margin-bottom: 0;
}
.sidebar-nav {
    margin: 0 0 0;
    padding: 0 0 0;
    list-style: none;
    width: 100%;
    background: #fff;
    border-top-left-radius: 7px;
    overflow: hidden;
    border-bottom-left-radius: 7px;
    -webkit-transition: all 0.35s ease;
    transition: all 0.35s ease;
}
.sidebar-nav  input , .sidebar-nav input:focus{
   outline: none; 
   box-shadow: none;
   border-radius: 0;
}
.input_wrapper  input:focus{
    border-bottom:1px solid #fff;
}

.sidebar-nav.open_sidebar {
    transition: all 0.35s ease;
}

.sidebar-nav li {
    position: relative; 
    line-height: 20px;
    display: inline-block;
    width: 100%;
}
.input_wrapper{
    padding: 25px;
    background: #4285F4 !important;
}

.input_wrapper input{
    border: 0;
    width: 100% !important;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    border-radius: 0px;
    border-bottom:1px solid #b2b2b2;
    background: transparent;
    color: #fff !important;
    padding: 0;
    margin: 15px 23px;
}
.input_wrapper i {
    color: #fff;
    vertical-align: middle;
    padding-top: 14px;
    font-size: 12px;
    position: absolute;
    top: 12px;
    left: -10px;
}
.input_wrapper i.fa.fa-circle-thin:after {
    content: "";
    background: transparent;
    height: 100%;
    position: absolute;
    left: 3px;
    width: 0px;
    top: 32px;
    /* border: 1px dashed #ddd; */
}
.input_wrapper label {
    position: relative;
    width: 92%;
}

.input_wrapper input::placeholder{
    color: #fff;
}
.input_wrapper input::-webkit-input-placeholder{
    color: #fff;
}
.input_wrapper input::-moz-input-placeholder{
    color: #fff;
}



.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 20px;
    line-height: 44px;
}
.sidebar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: #222;
    box-shadow: none;
}

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/

.hamburger {
    position: fixed;
    top: 72px;
    left: 34rem;
    z-index: 999;
    display: block;
    width: 32px;
    height: 32px;
    margin-left: 15px;
    background: transparent;
    border: none;
    outline: none;
}
.hamburger  i{
    font-size: 18px;
}






.hamburger.is-closed:hover .hamb-top {
  top: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px;  
}
.hamburger.is-open .hamb-top { 
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open .hamb-middle { display: none; }
.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.date_wapper{
    padding: 25px;
}
.date_wapper input {
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 0;
    font-size: 12px;
    width: 100% !important;
}
.date_wapper input + span.input-group-addon{
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #ddd;
    font-size: 11px;
}

.date_wapper h6 {
    margin-bottom: 8px;
    margin-top: 20px;
}
.autocomplete-dropdown-map-container{
    background-color: #fff;
    width: 100%;
    border-radius: 3px !important;
        /* left: 36px !important; */
        margin-left: 20px;
        position: absolute;
        z-index: 11;
        left: 3px;
        top:46px;
}

.autocomplete-dropdown-map-container>div{
    font-size: 12px;
    padding:10px;
    border-bottom: 1px solid grey;
    cursor: pointer;
    transition: all 0.35s ease;
}
.autocomplete-dropdown-map-container>div:last-child{
    border-bottom: 0;
}
.autocomplete-dropdown-map-container>div:hover{
  color: #fff;
  background-color: grey;
  transition: all 0.35s ease;
}
.checkout_time .react-datepicker-popper{
   width: 295px;
}
.checkout_time .react-datepicker-wrapper{
    width: 100%;
}
.checkout_time .react-datepicker__input-container{
    width: 92%;
}