.login-holder:after {
    background: #175066 !important;
    position: absolute;
    top: 0;
    content: '';
    left: 0;
    width: 100%;
    height: 100%;
}
.login {
    position: relative;
    z-index: 9;
}
.muitable tr:nth-child(even) {
    background-color:#fff;
    color:#000;
}

.muitable tr:nth-child(odd) {
    background-color:#fbfcfd;
}
.muitable table{
    border: 1px solid #e7ecf1;
    width:100%
}
.muitable th,td{
    border: 1px solid #e7ecf1;
}
.muitable tr td:nth-child(2) {font-weight:bold; cursor: pointer;}

.CarrierTable th:nth-child(6) {
    width: 6%;
}
.CarrierTable th:nth-child(5) {
    width: 15%;
}
 .CarrierTable th:nth-child(4) {
    width: 17%;
}

 .CarrierTable th:nth-child(3) {
    width: 50%;
}

.CarrierTable th:nth-child(2) {
    width: 10%;
}

.CarrierTable th:nth-child(1) {
    width: 15% ;
}
.CarrierTable,.muitable {
    z-index: 0;
    position: relative;
}

.CustomerTable th:nth-child(6) {
    width: 6%;
}
.CustomerTable th:nth-child(5) {
    width: 12%;
}
 .CustomerTable th:nth-child(4) {
    width: 10%;
}

 .CustomerTable th:nth-child(3) {
    width: 10%;
}

.CustomerTable th:nth-child(2) {
    width: 40%;
}

.CustomerTable th:nth-child(1) {
    width: 15% ;
}
.AgentTable th:nth-child(6) {
    width: 10%;
}
.AgentTable th:nth-child(5) {
    width: 10%;
}
 .AgentTable th:nth-child(4) {
    width: 6%;
}

 .AgentTable th:nth-child(3) {
    width: 10%;
}

.AgentTable th:nth-child(2) {
    width: 40%;
}

.AgentTable th:nth-child(1) {
    width: 15% ;
}
.DriverTable th:nth-child(6) {
    width: 10%;
}
.DriverTable th:nth-child(5) {
    width: 10%;
}
 .DriverTable th:nth-child(4) {
    width: 6%;
}

 .DriverTable th:nth-child(3) {
    width: 10%;
}

.DriverTable th:nth-child(2) {
    width: 40%;
}

.DriverTable th:nth-child(1) {
    width: 15% ;
}


.label,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
}
body {
  overflow: unset !important;
}
table.tableUserList thead tr th,
table.tableUserList tbody tr td {
  border-left: 1px solid #e7ecf1;
  height: auto !important;
}

table.tableUserList thead tr th {
  padding: 10px 18px !important;
  cursor: pointer;
  white-space: normal !important;
}

table.tableUserList tbody tr td {
  padding: 4px 18px !important;
}

table.tableUserList tbody tr,
table.tableUserList thead tr {
  height: auto !important;
}

table.tableUserList thead tr th span {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Open Sans", sans-serif !important;
  color: #000 !important;
}

table.tableUserList thead tr th:first-child {
  height: 0 !important;
  width: 0 !important;
  display: none;
}

[class*="MuiTypography-caption-"] {
  font-size: 12px !important;
}

[class*="MuiTablePagination-select-"] {
  font-size: 12px !important;
  padding-top: 15px !important;
}

[class*="MuiTablePagination-selectIcon-"] {
  margin-top: 6px;
  padding-left: 6px;
}

table.tableUserList tbody tr:nth-child(2n + 1):not(:hover) td,
table.tableUserList tbody tr:not(:hover) td:first-child {
  background: #fbfcfd !important;
}

table.tableUserList tbody tr td:first-child,
table.tableUserList tbody tr td.actionBtn {
  text-align: left !important;
}

.tableUserList > tbody > tr:hover > td,
.tableUserList > tbody > tr:hover td:first-child {
  background-color: #eef1f5;
}

.tableUserList > tbody > tr:hover,
.tableUserList > tbody > tr.active > td,
.tableUserList > tbody > tr.active > th,
.tableUserList > tbody > tr > td.active,
.tableUserList > tbody > tr > th.active,
.tableUserList > tfoot > tr.active > td,
.tableUserList > tfoot > tr.active > th,
.tableUserList > tfoot > tr > td.active,
.tableUserList > tfoot > tr > th.active,
.tableUserList > thead > tr.active > td,
.tableUserList > thead > tr.active > th,
.tableUserList > thead > tr > td.active,
.tableUserList > thead > tr > th.active {
  background-color: #eef1f5;
}

table.tableUserList span.sorting {
  position: absolute;
  right: 0;
  top: 50%;
  height: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

table.tableUserList span.sorting svg {
  display: block !important;
  margin: -12px 0 -18px;
  opacity: 0.2;
}

table.tableUserList span.sort {
  position: absolute;
  right: 0;
  top: 50%;
  height: auto;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
div[class*="jss"],
span[class*="jss"] {
  font-size: 12px;
  font-weight: 700;
}
.customTableList {
  border: 1px solid #e7ecf1;
  border-left: 0;
  overflow-x: auto;
}

.customTableList > div {
  overflow: hidden !important;
}

[class*="MuiTablePagination-toolbar-"],
[class*="MuiTypography-caption-"],
[class*="MuiTypography-caption-"] {
  font-weight: 700 !important;
}

.editLink {
  cursor: pointer;
  font-weight: 700;
}

.searchField {
  width: 300px;
  display: inline-block;
  margin-right: 20px;
  border: 1px solid #c2cad8;
  position: relative;
  vertical-align: top;
}

.searchField .form-control {
  height: 100%;
}

.searchField input {
  width: 100%;
  height: 100%;
  border: none;
}
.searchField input:focus {
  outline: 0 none;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.searchField .removeSearchBtn {
  position: absolute;
  top: 6px;
  right: 10px;
  z-index: 9;
  cursor: pointer;
  color: #ccc;
}

.searchField input:focus + .removeSearchBtn {
  color: #000;
}

.btnShowall button {
  font-size: 13px;
}

[class*="MuiPopover-paper-"] li {
  font-size: 14px;
}
.btnShowall div[role="tooltip"] {
  z-index: 999;
}

.btnShowall div[role="tooltip"] li {
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
@media only screen and (max-width: 768px) {
  .customTableList > div {
    width: 850px;
  }
}
.tableUserList1 th:last-child,
.tableUserList1 td:last-child {
  width: 6%;
}
.tableUserList1 th:nth-child(6),
.tableUserList1 td:nth-child(5) {
  width: 11%;
}
.tableUserList1 th:nth-child(4),
.tableUserList1 td:nth-child(3) {
  width: 32%;
}

.tableUserList1 th:nth-child(3),
.tableUserList1 td:nth-child(2) {
  width: 10%;
}
.tableUserList1 th:nth-child(2),
.tableUserList1 td:nth-child(1) {
  width: 20%;
}
.d-flex {
  display: flex !important;
}
.justify-content-between {
  justify-content: space-between !important;
}


.page-sidebar .page-sidebar-menu>li>a.selected,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a.selected {
    background: #f2f6f9;
    color: #175066;
    
}

.page-sidebar .page-sidebar-menu>li>a.selected,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a.selected {
    border-left: 3px solid #5C9ACF!important;
    margin-left: -3px;
    padding-left: 15px;
}
.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>.sub-menu>li>a {
    color: #175066;
   
}
@media (max-height:760px){
    .page-sidebar-menu{
        height: calc(100vh - 75px);
        overflow-y: auto;
        padding: 0 0 18px !important;
    }
}



.page-sidebar-menu::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     border-radius: 0px;
     background-color: #F5F5F5;
 }
 
 .page-sidebar-menu::-webkit-scrollbar
 {
     width:10px;
     background-color: #F5F5F5;
 }
 
 .page-sidebar-menu::-webkit-scrollbar-thumb
 {
     border-radius: 0;
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
     background-color: #999;
 }
 .page-sidebar-menu{
    scrollbar-color: #999 #F5F5F5;
 }
.react-datepicker{font-size:12px  !important}
.react-datepicker__current-month, .react-datepicker-time__header {
    font-size: 11px  !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {width: 2.7rem  !important;}
.filter {
    margin-left: 5px;
    cursor: pointer;
}

.form-row label {
    text-align: left;
    margin-top: 10px;
    display: flex;
}

.filterSerchDiv {
    display: inline-block;
    margin: 14px 15px 0px;
}

.downloadCsv {
    margin-right: 15px;
    cursor: pointer;
}
.customeLeft .Select-input{
    text-align: left !important;
    width: 100%;
}
.customeLeft .Select-placeholder{left: 0px !important;right: inherit;}
.loadIDTabs {
    position: absolute !important;
    left: 30% !important;
    bottom: -30px !important;
}

.showRowForEdit {
    background-color: white;
    border: 1px solid;
    border-color: #f65335;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.showRowForEdit-items {
    /* margin-right: 30px; */
}

.AuditTrailForm{
    padding: 15px !important;
}

.AuditTrailForm table thead tr th {
    font-size: 12px !important;
    font-weight: bold !important;
}
.AuditTrailForm table tbody tr td {
    font-size: 12px !important;
}
.AuditTrailForm h6{
    font-size: 14px !important;
    font-weight: bold !important;
}
/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/
.tools_map{
    display: flex;
    padding-bottom: 30px;
}
.tools_map .map_wrapper {
    width: 80%;
}
#sidebar-wrapper{
    margin-bottom: 0;
}
.sidebar-nav {
    margin: 0 0 0;
    padding: 0 0 0;
    list-style: none;
    width: 100%;
    background: #fff;
    border-top-left-radius: 7px;
    overflow: hidden;
    border-bottom-left-radius: 7px;
    transition: all 0.35s ease;
}
.sidebar-nav  input , .sidebar-nav input:focus{
   outline: none; 
   box-shadow: none;
   border-radius: 0;
}
.input_wrapper  input:focus{
    border-bottom:1px solid #fff;
}

.sidebar-nav.open_sidebar {
    transition: all 0.35s ease;
}

.sidebar-nav li {
    position: relative; 
    line-height: 20px;
    display: inline-block;
    width: 100%;
}
.input_wrapper{
    padding: 25px;
    background: #4285F4 !important;
}

.input_wrapper input{
    border: 0;
    width: 100% !important;
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    border-radius: 0px;
    border-bottom:1px solid #b2b2b2;
    background: transparent;
    color: #fff !important;
    padding: 0;
    margin: 15px 23px;
}
.input_wrapper i {
    color: #fff;
    vertical-align: middle;
    padding-top: 14px;
    font-size: 12px;
    position: absolute;
    top: 12px;
    left: -10px;
}
.input_wrapper i.fa.fa-circle-thin:after {
    content: "";
    background: transparent;
    height: 100%;
    position: absolute;
    left: 3px;
    width: 0px;
    top: 32px;
    /* border: 1px dashed #ddd; */
}
.input_wrapper label {
    position: relative;
    width: 92%;
}

.input_wrapper input::-webkit-input-placeholder{
    color: #fff;
}

.input_wrapper input::placeholder{
    color: #fff;
}
.input_wrapper input::-webkit-input-placeholder{
    color: #fff;
}
.input_wrapper input::-moz-input-placeholder{
    color: #fff;
}



.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 20px;
    line-height: 44px;
}
.sidebar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: #222;
    box-shadow: none;
}

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/

.hamburger {
    position: fixed;
    top: 72px;
    left: 34rem;
    z-index: 999;
    display: block;
    width: 32px;
    height: 32px;
    margin-left: 15px;
    background: transparent;
    border: none;
    outline: none;
}
.hamburger  i{
    font-size: 18px;
}






.hamburger.is-closed:hover .hamb-top {
  top: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px;  
}
.hamburger.is-open .hamb-top { 
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open .hamb-middle { display: none; }
.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.date_wapper{
    padding: 25px;
}
.date_wapper input {
    border: 0;
    border-bottom: 1px solid #ddd;
    padding: 0;
    font-size: 12px;
    width: 100% !important;
}
.date_wapper input + span.input-group-addon{
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #ddd;
    font-size: 11px;
}

.date_wapper h6 {
    margin-bottom: 8px;
    margin-top: 20px;
}
.autocomplete-dropdown-map-container{
    background-color: #fff;
    width: 100%;
    border-radius: 3px !important;
        /* left: 36px !important; */
        margin-left: 20px;
        position: absolute;
        z-index: 11;
        left: 3px;
        top:46px;
}

.autocomplete-dropdown-map-container>div{
    font-size: 12px;
    padding:10px;
    border-bottom: 1px solid grey;
    cursor: pointer;
    transition: all 0.35s ease;
}
.autocomplete-dropdown-map-container>div:last-child{
    border-bottom: 0;
}
.autocomplete-dropdown-map-container>div:hover{
  color: #fff;
  background-color: grey;
  transition: all 0.35s ease;
}
.checkout_time .react-datepicker-popper{
   width: 295px;
}
.checkout_time .react-datepicker-wrapper{
    width: 100%;
}
.checkout_time .react-datepicker__input-container{
    width: 92%;
}
