.label,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
}
body {
  overflow: unset !important;
}
table.tableUserList thead tr th,
table.tableUserList tbody tr td {
  border-left: 1px solid #e7ecf1;
  height: auto !important;
}

table.tableUserList thead tr th {
  padding: 10px 18px !important;
  cursor: pointer;
  white-space: normal !important;
}

table.tableUserList tbody tr td {
  padding: 4px 18px !important;
}

table.tableUserList tbody tr,
table.tableUserList thead tr {
  height: auto !important;
}

table.tableUserList thead tr th span {
  font-size: 14px !important;
  font-weight: 600 !important;
  font-family: "Open Sans", sans-serif !important;
  color: #000 !important;
}

table.tableUserList thead tr th:first-child {
  height: 0 !important;
  width: 0 !important;
  display: none;
}

[class*="MuiTypography-caption-"] {
  font-size: 12px !important;
}

[class*="MuiTablePagination-select-"] {
  font-size: 12px !important;
  padding-top: 15px !important;
}

[class*="MuiTablePagination-selectIcon-"] {
  margin-top: 6px;
  padding-left: 6px;
}

table.tableUserList tbody tr:nth-child(2n + 1):not(:hover) td,
table.tableUserList tbody tr:not(:hover) td:first-child {
  background: #fbfcfd !important;
}

table.tableUserList tbody tr td:first-child,
table.tableUserList tbody tr td.actionBtn {
  text-align: left !important;
}

.tableUserList > tbody > tr:hover > td,
.tableUserList > tbody > tr:hover td:first-child {
  background-color: #eef1f5;
}

.tableUserList > tbody > tr:hover,
.tableUserList > tbody > tr.active > td,
.tableUserList > tbody > tr.active > th,
.tableUserList > tbody > tr > td.active,
.tableUserList > tbody > tr > th.active,
.tableUserList > tfoot > tr.active > td,
.tableUserList > tfoot > tr.active > th,
.tableUserList > tfoot > tr > td.active,
.tableUserList > tfoot > tr > th.active,
.tableUserList > thead > tr.active > td,
.tableUserList > thead > tr.active > th,
.tableUserList > thead > tr > td.active,
.tableUserList > thead > tr > th.active {
  background-color: #eef1f5;
}

table.tableUserList span.sorting {
  position: absolute;
  right: 0;
  top: 50%;
  height: auto;
  transform: translateY(-50%);
}

table.tableUserList span.sorting svg {
  display: block !important;
  margin: -12px 0 -18px;
  opacity: 0.2;
}

table.tableUserList span.sort {
  position: absolute;
  right: 0;
  top: 50%;
  height: auto;
  transform: translateY(-50%);
}
div[class*="jss"],
span[class*="jss"] {
  font-size: 12px;
  font-weight: 700;
}
.customTableList {
  border: 1px solid #e7ecf1;
  border-left: 0;
  overflow-x: auto;
}

.customTableList > div {
  overflow: hidden !important;
}

[class*="MuiTablePagination-toolbar-"],
[class*="MuiTypography-caption-"],
[class*="MuiTypography-caption-"] {
  font-weight: 700 !important;
}

.editLink {
  cursor: pointer;
  font-weight: 700;
}

.searchField {
  width: 300px;
  display: inline-block;
  margin-right: 20px;
  border: 1px solid #c2cad8;
  position: relative;
  vertical-align: top;
}

.searchField .form-control {
  height: 100%;
}

.searchField input {
  width: 100%;
  height: 100%;
  border: none;
}
.searchField input:focus {
  outline: 0 none;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.searchField .removeSearchBtn {
  position: absolute;
  top: 6px;
  right: 10px;
  z-index: 9;
  cursor: pointer;
  color: #ccc;
}

.searchField input:focus + .removeSearchBtn {
  color: #000;
}

.btnShowall button {
  font-size: 13px;
}

[class*="MuiPopover-paper-"] li {
  font-size: 14px;
}
.btnShowall div[role="tooltip"] {
  z-index: 999;
}

.btnShowall div[role="tooltip"] li {
  font-size: 13px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
@media only screen and (max-width: 768px) {
  .customTableList > div {
    width: 850px;
  }
}
.tableUserList1 th:last-child,
.tableUserList1 td:last-child {
  width: 6%;
}
.tableUserList1 th:nth-child(6),
.tableUserList1 td:nth-child(5) {
  width: 11%;
}
.tableUserList1 th:nth-child(4),
.tableUserList1 td:nth-child(3) {
  width: 32%;
}

.tableUserList1 th:nth-child(3),
.tableUserList1 td:nth-child(2) {
  width: 10%;
}
.tableUserList1 th:nth-child(2),
.tableUserList1 td:nth-child(1) {
  width: 20%;
}
.d-flex {
  display: flex !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
