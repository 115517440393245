.filter {
    margin-left: 5px;
    cursor: pointer;
}

.form-row label {
    text-align: left;
    margin-top: 10px;
    display: flex;
}

.filterSerchDiv {
    display: inline-block;
    margin: 14px 15px 0px;
}

.downloadCsv {
    margin-right: 15px;
    cursor: pointer;
}
.customeLeft .Select-input{
    text-align: left !important;
    width: 100%;
}
.customeLeft .Select-placeholder{left: 0px !important;right: inherit;}