
.page-sidebar .page-sidebar-menu>li>a.selected,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a.selected {
    background: #f2f6f9;
    color: #175066;
    
}

.page-sidebar .page-sidebar-menu>li>a.selected,
.page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu>li>a.selected {
    border-left: 3px solid #5C9ACF!important;
    margin-left: -3px;
    padding-left: 15px;
}
.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed>li:not(.heading):hover>.sub-menu>li>a {
    color: #175066;
   
}
@media (max-height:760px){
    .page-sidebar-menu{
        height: calc(100vh - 75px);
        overflow-y: auto;
        padding: 0 0 18px !important;
    }
}



.page-sidebar-menu::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     border-radius: 0px;
     background-color: #F5F5F5;
 }
 
 .page-sidebar-menu::-webkit-scrollbar
 {
     width:10px;
     background-color: #F5F5F5;
 }
 
 .page-sidebar-menu::-webkit-scrollbar-thumb
 {
     border-radius: 0;
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
     background-color: #999;
 }
 .page-sidebar-menu{
    scrollbar-color: #999 #F5F5F5;
 }