.loadIDTabs {
    position: absolute !important;
    left: 30% !important;
    bottom: -30px !important;
}

.showRowForEdit {
    background-color: white;
    border: 1px solid;
    border-color: #f65335;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.showRowForEdit-items {
    /* margin-right: 30px; */
}

.AuditTrailForm{
    padding: 15px !important;
}

.AuditTrailForm table thead tr th {
    font-size: 12px !important;
    font-weight: bold !important;
}
.AuditTrailForm table tbody tr td {
    font-size: 12px !important;
}
.AuditTrailForm h6{
    font-size: 14px !important;
    font-weight: bold !important;
}