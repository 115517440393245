.login-holder:after {
    background: #175066 !important;
    position: absolute;
    top: 0;
    content: '';
    left: 0;
    width: 100%;
    height: 100%;
}
.login {
    position: relative;
    z-index: 9;
}